import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import BaseSectionHeader from "../../../../components/BaseSectionHeader/BaseSectionHeader";
import MovieBoxCoverStillDisplay from "../../../../components/MovieBoxCoverStillDisplay/MovieBoxCoverStillDisplay";

import {
	getMostWatchedPageRoute,
	getMovieDetailsPageRoute,
	getSceneDetailsPageRoute
} from "../../../../services/navigation/navigation.service.routes";
import { getPosterImage } from "../../../../services/images-service/images.service";
import { isWeb } from "../../../../services/util-service/util.service";
import { SecondaryNavigation, navigationOptions } from "../../../../constants/navigation";
import useInViewport from "../../../../hook/useInViewport";
import useMoviesScenesData from "../../../../hook/useMoviesScenesData";
import { isUserLoggedIn } from "../../../../services/token-service/token.service";

import "./MostPopular.scss";

const MostPopular = () => {
	const { t } = useTranslation();
	const containerRef = useRef(null);
	const isVisible = useInViewport(containerRef);

	const { videos, loading, error } = useMoviesScenesData({
		videosType: navigationOptions.movies,
		videosSortType: SecondaryNavigation.MostWatched,
		isVisible
	});

	const slicedVideos = isWeb() ? videos : videos.slice(0, 4);

	const renderMovie = ({
							 movieId,
							 first_scene = {},
							 cover_image = {},
							 title,
							 titleNs
						 },
						 index
	) => {
		const movieName = titleNs || title || '';
		const { url, alt } = cover_image;
		const sampleVideoUrl = first_scene.sample_video;
		const movieNavigationRoute = isUserLoggedIn() ? getMovieDetailsPageRoute(movieId, movieName) : '/join';
		const sceneNavigationRoute = getSceneDetailsPageRoute(movieId, movieName, index);
		const frontPoster = getPosterImage(first_scene.cover_images);

		return (
			<MovieBoxCoverStillDisplay
				sampleVideoUrl={sampleVideoUrl}
				movieNavigationRoute={movieNavigationRoute}
				sceneNavigationRoute={sceneNavigationRoute}
				movieId={movieId}
				alt={alt || title}
				boxCoverUrl={url}
				heroStillUrl={frontPoster.url}
				title={isWeb() ? '' : movieName}
				stars={first_scene.stars}
				key={movieId + '-' + index}
				viewType={isWeb() ? navigationOptions.movies : navigationOptions.scenes}
			/>
		)
	};

	const renderView = () => {
		let view = null;

		if (loading && slicedVideos.length === 0) return null;

		if (error) return null;

		if (isWeb()) {
			const settings = {
				dots: false,
				slidesToShow: 6,
				infinite: true,
				slidesToScroll: 3,
				swipe: false,
				className: "MostWatchedCarousel"
			};
			view = <Slider {...settings}>{slicedVideos.map(renderMovie)}</Slider>;
		} else {
			view = slicedVideos.map(renderMovie);
		}

		return (
			<>
				<BaseSectionHeader
					primaryText={t('MostPopular.text')}
					secondaryLink={getMostWatchedPageRoute()}
					secondaryText={t('MostPopular.viewAll')}
				/>
				{view}
			</>
		)
	};

	return <div className='MostPopular' ref={containerRef}>{renderView()}</div>
};

MostPopular.displayName = 'MostPopular';

export default MostPopular;