import axios from "axios";

export const urls = {
	scenesFeed: "/frontend/scenes/feed"
};

export const getScenes = (params, page, sortBy) => {
	if (page) {
		params.page = page;
	}
	if (sortBy) {
		params.sort_by = sortBy;
	}
	return axios.get(urls.scenesFeed, { params });
};


